import React from 'react';
import * as API from '@ben-toogood/hutch';
import { Helmet } from 'react-helmet';
import './ClientBranding.scss';

interface Props {
  children?: any;
  clientSlug?: string;
  pageTitle?: string;
  overrides?: any;
  loading?: boolean;
  onClientSet?: (client: API.Client) => void;
  client?: API.Client;
}

interface State {
  client?: API.Client;
  fontLoaded?: boolean;
}

const fontFamilyURLs: Record<API.FontFamily,string> = {
  [API.FontFamily.Unknown]: '/fonts/NewYork.ttf',
  [API.FontFamily.Futura]: '/fonts/Futura.ttc',
  [API.FontFamily.NewYork]: '/fonts/NewYork.ttf',
  [API.FontFamily.Montserrat]: '/fonts/Montserrat.ttf',
  [API.FontFamily.OpenSans]: '/fonts/OpenSans.ttf',
  [API.FontFamily.UntitledSans]: '/fonts/UntitledSans.ttf',
  [API.FontFamily.Poppins]: '/fonts/Poppins.ttf',
}

const defaultColor = '000000';
const defaultFontFamily = 'DM Sans, sans-serif';

export default class ClientBranding extends React.Component<Props,State> {
  readonly state: State = {};

  constructor(props: Props) {
    super(props);

    if(props.client) {
      this.state = { client: props.client }
      this.setBranding(props.client);
    };
  }
  
  componentDidMount() {
    if(this.props.clientSlug && !this.state.client) this.loadBranding();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(prevProps.clientSlug !== this.props.clientSlug && this.props.clientSlug) this.loadBranding();

    if(prevProps.client !== this.props.client && this.props.client) {
      this.setState({ client: this.props.client });
    }

    if(prevState.client !== this.state.client && this.state.client) {
      if(this.props.onClientSet) this.props.onClientSet(this.state.client);
      this.setBranding(this.state.client);
    }
  }

  setBranding(client: API.Client) {
    document.title = client.name;
    if(this.props.pageTitle) document.title += (' | ' + this.props.pageTitle);

    document.getElementById("favicon")?.setAttribute('href', client.branding_favicon_url!);
    this.loadFont(client.branding_font_family || API.FontFamily.Unknown);
  }

  loadBranding() {
    API.Call("clients/ReadBySlug", { slug: this.props.clientSlug })
      .then(rsp => this.setState({ client: { ...rsp.client, ...(this.props.overrides || {}) } }))
      .catch(err => alert(err));
  }

  loadFont(name: API.FontFamily) {
    const fontURLs = fontFamilyURLs[name];
    if(!fontURLs?.length) {
      this.setState({ fontLoaded: true });
      return;
    }

    var font = new FontFace(name!, `url(${fontURLs})`);

    font.load()
      .then(fontFace => {
        document.fonts.add(fontFace);
        this.setState({ fontLoaded: true });
      }).catch(err => {
        this.setState({ fontLoaded: true, client: { ...this.state.client!, branding_font_family: defaultFontFamily as any }});
        console.warn(err);
      });
  }
  
  render() {
    const { client, fontLoaded } = this.state;

    var loadingStatus;
    if(this.props.loading) {
      loadingStatus = 'pending';
    } else if(client && fontLoaded) {
      loadingStatus = 'complete';
    }

    const styles = {
      '--client-font-family': client?.branding_font_family || defaultFontFamily,
      '--client-primary-color': '#' + (client?.branding_color || defaultColor),
      '--client-primary-color-faded': `#${(client?.branding_color || defaultColor)}15`,
      '--client-primary-color-very-faded': `#${(client?.branding_color || defaultColor)}05`,
    } as React.CSSProperties;

    let title = '';
    if(client) title = this.props.pageTitle ? `${client.name} | ${this.props.pageTitle}` : client.name;

    return <div style={styles} className='ClientBranding'>
      <Helmet>
        <title>{title}</title>
        { title.length ? <meta property="og:title" content={title} /> : null }
        { client?.branding_banner_image_url ? <meta property="og:image" content={client.branding_banner_image_url} /> : null }
      </Helmet>

      <div className={`Loading ${loadingStatus}`}>
        <img src='/images/spinner.svg' alt='Loading' />
      </div>

      <div className={`Inner ${client?.branding_banner_image_url ? '' : 'hide-banner-img'}`}>
        <div className='Left'>
          { this.props.children }
        </div>

        <div className='Right' style={{backgroundImage: `url(${client?.branding_banner_image_url})`}} />
      </div>
    </div>
  }
}