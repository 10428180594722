import React from "react";
import * as API from "@ben-toogood/hutch";

interface Props {
  id: string;
  status: API.OrderStatus;
  onHold: () => void;
  goBack: () => void;
  onComplete: () => void;
  onError: (err: string) => void;
}

interface State {
  reason?: string;
  loading?: boolean;
  view: "reasons" | "other" | "suggest-hold" | "confirm";
}

const cancelResons = {
  NotHome: "I am not going to be home for the delivery",
  NotNeeded: "I no longer need the items",
  InError: "I placed the order in error",
  Other: "Other",
};

export default class CancelOrder extends React.Component<Props, State> {
  readonly state: State = { view: "reasons" };

  reasons = [
    cancelResons.NotHome,
    cancelResons.NotNeeded,
    cancelResons.InError,
    cancelResons.Other,
  ].filter((r) => {
    // do not shop "NotHome" if the order is already on hold
    if (r === cancelResons.NotHome)
      return this.props.status === API.OrderStatus.Pending;
    return true;
  });

  constructor(props: Props) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Cancel Order",
    }).catch(console.warn);
  }

  render() {
    switch (this.state.view) {
      case "reasons":
        return this.renderReasons();
      case "suggest-hold":
        return this.renderSuggestHold();
      case "other":
        return this.renderOther();
      default:
        return this.renderConfirm();
    }
  }

  cancel() {
    if (this.state.loading) return;
    this.setState({ loading: true });

    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Cancel Order",
      metadata: { reason: this.state.reason },
    }).catch(console.warn);

    API.Call("support/CancelOrder", {
      id: this.props.id,
      reason: this.state.reason,
    })
      .then(this.props.onComplete)
      .catch(this.props.onError);
  }

  renderOther() {
    return (
      <div className="Tracking">
        <button className="go-back" onClick={this.props.goBack}>
          <span role="img" aria-label="Go back">
            ↩
          </span>{" "}
          Go back
        </button>

        <h2>I need to cancel my order</h2>

        <textarea
          autoFocus
          placeholder="Cancellation reason"
          onChange={(e) =>
            this.setState({ reason: `Other - ${e.target.value}` })
          }
        />

        <section className="rows rows-actions">
          <div
            onClick={() =>
              this.setState({ view: "reasons", reason: undefined })
            }
          >
            <p>Select a different reason</p>
          </div>
          <div className="danger" onClick={this.cancel}>
            <p>Cancel my order</p>
          </div>
        </section>
      </div>
    );
  }

  renderConfirm() {
    return (
      <div className="Tracking">
        <button className="go-back" onClick={this.props.goBack}>
          <span role="img" aria-label="Go back">
            ↩
          </span>{" "}
          Go back
        </button>

        <h2>I need to cancel my order</h2>
        <p className="subtitle">
          Are you sure you want to cancel your order? The reason provided was:{" "}
          {this.state.reason}.
        </p>

        <section className="rows rows-actions">
          <div
            onClick={() =>
              this.setState({ view: "reasons", reason: undefined })
            }
          >
            <p>Select a different reason</p>
          </div>
          <div className="danger" onClick={this.cancel.bind(this)}>
            <p>
              {this.state.loading
                ? "Cancelling..."
                : "I'm sure, cancel my order"}
            </p>
          </div>
        </section>
      </div>
    );
  }

  renderSuggestHold() {
    return (
      <div className="Tracking">
        <button className="go-back" onClick={this.props.goBack}>
          <span role="img" aria-label="Go back">
            ↩
          </span>{" "}
          Go back
        </button>

        <h2>I need to cancel my order</h2>
        <p className="subtitle">
          Rather than cancelling your order, why not pause it until you're able
          to receive the order?
        </p>

        <section className="rows rows-actions">
          <div onClick={this.props.onHold}>
            <p>Awesome, let's pause my order for now</p>
          </div>
          <div
            onClick={() =>
              this.setState({ view: "reasons", reason: undefined })
            }
          >
            <p>Select a different reason</p>
          </div>
          <div className="danger" onClick={this.cancel.bind(this)}>
            <p>I'm sure, cancel my order</p>
          </div>
        </section>
      </div>
    );
  }

  renderReasons() {
    const { status } = this.props;

    let message = "";
    let actions = <div />;
    if (
      status === API.OrderStatus.Pending ||
      status === API.OrderStatus.Blocked
    ) {
      message =
        "We're sorry to hear you need to cancel your order. Please let us know why you need to cancel the order from one of the options list below";
      actions = (
        <section className="rows rows-actions">
          {this.reasons.map((r) => {
            const onClick = () => {
              if (r === cancelResons.NotHome) {
                this.setState({ reason: r, view: "suggest-hold" });
              } else if (r === cancelResons.Other) {
                this.setState({ reason: r, view: "other" });
              } else {
                this.setState({ reason: r, view: "confirm" });
              }
            };

            return (
              <div key={r} onClick={onClick}>
                <p>{r}</p>
              </div>
            );
          })}
        </section>
      );
    } else if (status === API.OrderStatus.Shipped) {
      message =
        "We're sorry to hear that you need to cancel your order. Unfortunately your order has already been dispatched so we're unable to cancel the order however you are able to return your order within 30 days for a full refund.";
      actions = (
        <section className="rows rows-actions">
          <div>
            <p>Return my order</p>
          </div>
        </section>
      );
    } else {
      message =
        "We're sorry to hear that you need to cancel your order. Unfortunately your order has already been processed so we're unable to cancel the order at this stage. Once your order is delivered, please return to this page for instructions on how to return your order.";
    }

    return (
      <div className="Tracking">
        <button className="go-back" onClick={this.props.goBack}>
          <span role="img" aria-label="Go back">
            ↩
          </span>{" "}
          Go back
        </button>

        <h2>I need to cancel my order</h2>
        <p className="subtitle">{message}</p>

        {actions}
      </div>
    );
  }
}
