import React from 'react';
import qs from 'query-string';
import * as API from '@ben-toogood/hutch';
import ClientBranding from '../../components/ClientBranding';
import './ClaimGift.scss';

interface Props {
  match?: any;
  location: any;
}

interface State {
  client?: API.Client;
  address?: API.Address;
  loading?: boolean;
  complete?: boolean;
  clientSlug?: string;
  maxItems?: number;
  items?: Item[];
  selectedItemIDs?: string[];
  tracking_id?: string;
}

interface ValidateGiftResponse {
  client_slug: string;
  name: string;
  phone_number?: string;
  maximum_items?: number;
  items?: Item[];
}

interface Item {
  id: string;
  name: string;
}

export default class ClaimGift extends React.Component<Props,State> {
  readonly state: State = {};

  constructor(props:Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { demo_slug, gift_id } = this.props.match.params;
    if(demo_slug) this.setState({ clientSlug: demo_slug });

    if(gift_id) {
      API.Call("gifting/ValidateGift", { id: gift_id })
        .then((rsp: ValidateGiftResponse) => {
          this.setState({ 
            clientSlug: rsp.client_slug, 
            address: { name: rsp.name, phone_number: rsp.phone_number } as API.Address,
            maxItems: rsp.maximum_items,
            items: rsp.items,
          });
        })
        .catch(err => alert(err));
    }
  }

  onSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.setState({ loading: true });

    const { gift_id } = this.props.match.params;

    // demo mode
    if(!gift_id) {
      setTimeout(() => this.setState({ loading: false, complete: true }), 800);
      return;
    }

    // execute the api call
    API.Call("gifting/RedeemGift", { id: gift_id, shipping_address: this.state.address, item_ids: this.state.selectedItemIDs })
      .then((rsp: { order_id: string }) => {
        this.setState({ complete: true, tracking_id: rsp.order_id })
        setTimeout(() => window.location.href = `https://tracking.tryhutch.co.uk/t/${rsp.order_id}`, 5000);
      })
      .catch(err => alert(err))
      .finally(() => this.setState({ loading: false }));
  }
  
  render() {
    return <ClientBranding
            pageTitle='Gifting'
            loading={this.state.loading}
            clientSlug={this.state.clientSlug}
            onClientSet={client => this.setState({ client })} 
            overrides={qs.parse(this.props.location.search)} >
      { this.renderInner() }
    </ClientBranding>
  }

  renderInner() {
    let { client, complete } = this.state;
    if(!client) return null;
    return complete ? this.renderConfirmation(client) : this.renderForm(client);
  }

  renderConfirmation(client: API.Client) {
    const { gift_id } = this.props.match.params;

    return <div className='ClaimGift confirmation'>
      {
        client.branding_logo_url ?
        <img id='logo' src={client.branding_logo_url} alt={client.name} /> :
        <h1 id='logo-text'>{client.name}</h1>
      }

      <p id='confirmation'>Your {this.state.client?.name} products are on their way! In a moment you will be redirected to track your products. If you are not redirected automatically, <a href={`https://tracking.tryhutch.co.uk/t/${this.state.tracking_id}`}>click here.</a></p>
    
      { gift_id ? null : <p id='demo-warning'>Please note this is a demo so a live gifting order has not been created</p> }
    </div>
  }

  renderForm(client: API.Client) {
    const addr = this.state.address;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if(!addr) return;
      this.setState({ address: { ...addr, [e.target.name]: e.target.value } });
    }

    return <div className='ClaimGift'>
      {
        client.branding_logo_url ?
        <img id='logo' src={client.branding_logo_url} alt={client.name} /> :
        <h1 id='logo-text'>{client.name}</h1>
      }

      <p id='intro'>You have been gifted some products from {client.name}! Please complete the form below so we know where to send your items.</p>

      <form onSubmit={this.onSubmit}>
        { this.state.maxItems ? <section className='items'>
          <h3>{this.state.maxItems === 1 ? 'Pick your product' : `Select up to ${this.state.maxItems} products`}</h3>
          { this.state.items?.map(i => {
            const selected = this.state.selectedItemIDs?.includes(i.id);
            const disabled = !selected && this.state.maxItems === this.state.selectedItemIDs?.length;

            const onClick = () => {
              if(disabled) return;

              if(selected) {
                this.setState({ selectedItemIDs: this.state.selectedItemIDs?.filter(x => x !== i.id) });
              } else {
                this.setState({ selectedItemIDs: [...(this.state.selectedItemIDs || []), i.id] });
              }
            }

            return <div onClick={onClick} className={`option-row ${disabled ? 'disabled' : ''}`} key={i.id}>
              <p>{i.name}</p>

              <div className={`circle ${selected ? 'selected' : ''}`} />
            </div>
          })}
        </section> : null }

        <section>
          <h3>Contact Details</h3>
          <input onChange={onChange} required type='text' name='name' value={addr?.name} placeholder='Name' minLength={5} maxLength={50} />
          <input onChange={onChange} required type='phone_number' name='phone_number' value={addr?.phone_number} placeholder='Phone Number' minLength={5} maxLength={15} />
          <input onChange={onChange} required type='email' name='email' value={addr?.email} placeholder='Email Address' minLength={5} maxLength={50} />
        </section>

        <section>
          <h3>Shipping Address</h3>
          <input onChange={onChange} required type='text' name='line_one' value={addr?.line_one} placeholder='Line 1' minLength={1} maxLength={50} />
          <input onChange={onChange} type='text' name='line_two' value={addr?.line_two} placeholder='Line 2' minLength={1} maxLength={50} />
          <input onChange={onChange} required type='text' name='city' value={addr?.city} placeholder='City' minLength={1} maxLength={50} />
          <input onChange={onChange} required type='text' name='country' value={addr?.country} placeholder='Country' minLength={1} maxLength={50} />
          <input onChange={onChange} type='text' name='postcode' value={addr?.postcode} placeholder='Postcode' maxLength={50} />
        </section>

        <input disabled={!!this.state.maxItems && !this.state.selectedItemIDs?.length} type='submit' value='Submit' />
      </form>
    </div>
  }
}