import React from "react";
import * as API from "@ben-toogood/hutch";

interface Props {
  id: string;
  goBack: () => void;
  onSuccess: () => void;
  onError: () => void;
}

interface State {
  loading?: boolean;
}

export default class HoldOrder extends React.Component<Props, State> {
  readonly state: State = {};

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Hold Order",
    }).catch(console.warn);
  }

  render(): JSX.Element {
    const onHold = () => {
      if (this.state.loading) return;
      this.setState({ loading: true });

      API.Call("support/LogEvent", {
        id: this.props.id,
        description: "Action Taken: Hold Order",
      }).catch(console.warn);

      API.Call("support/HoldOrder", { id: this.props.id })
        .then(this.props.onSuccess)
        .catch(this.props.onError);
    };

    return (
      <div className="Tracking">
        <button className="go-back" onClick={this.props.goBack}>
          <span role="img" aria-label="Go back">
            ↩
          </span>{" "}
          Go back
        </button>

        <h2>Do not ship my order yet</h2>
        <p className="subtitle">
          If you aren't able to receive your delivery, we can hold off on
          dispatching your order until a time that suits you. Once you are ready
          to receive your delivery, come back to this link and press{" "}
          <strong>Dispatch my order</strong>.
        </p>

        <section className="rows rows-actions">
          <div onClick={onHold}>
            <p>Hold my order</p>
          </div>
        </section>
      </div>
    );
  }
}
