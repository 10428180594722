import React, { createRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

interface Props {
  setImageURLs: (urls: string[]) => void;
}

interface State {
  images: Image[];
  s3?: S3Client;
}

interface Image {
  name?: string;
  url?: string;
}

export default class ImageUpload extends React.Component<Props, State> {
  uploader: React.RefObject<HTMLInputElement> = createRef();

  constructor(props: Props) {
    super(props);
    this.onImageSelected = this.onImageSelected.bind(this);
    this.state = { images: [] };
  }

  componentDidMount() {
    const client = new S3Client({
      region: "eu-west-2",
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: "eu-west-2",
        }),
        identityPoolId: "eu-west-2:b56842af-fbc9-4694-bb7f-4c2db26ba2ac",
      }),
    });

    this.setState({ s3: client });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const imageURLs = this.state.images
      .filter((i) => !!i.url)
      .map((i) => i.url!);
    const prevImageURLs = prevState.images
      .filter((i) => !!i.url)
      .map((i) => i.url!);
    if (imageURLs.length !== prevImageURLs.length)
      this.props.setImageURLs(imageURLs);
  }

  async onImageSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    this.setState({
      images: [...this.state.images, { name: file.name }],
    });

    try {
      const uuid = uuidv4();
      await this.state.s3!.send(
        new PutObjectCommand({
          Bucket: "hutch-issue-evidence",
          Key: `${uuid}.jpeg`,
          Body: file,
          ContentType: "image/jpeg",
          ContentDisposition: 'inline;filename="' + file.name + '"',
          ACL: "public-read",
        }),
      );

      const url = `https://hutch-issue-evidence.s3.eu-west-2.amazonaws.com/${uuid}.jpeg`;

      let images = [...this.state.images];
      const idx = images.findIndex((i) => i.name === file.name);
      images[idx] = { ...images[idx], url };
      this.setState({ images });
    } catch (err) {
      let images = this.state.images.filter((i) => i.name !== file.name);
      this.setState({ images });
      console.warn(err);
      alert(`Error uploading photo: ${err}`);
    }
  }

  render() {
    return (
      <div className="ImageUpload">
        <input
          ref={this.uploader}
          type="file"
          accept="image/*"
          onChange={this.onImageSelected}
        />

        <div className="upload" onClick={() => this.uploader.current?.click()}>
          <p>
            <span role="img" aria-label="Upload">
              📷
            </span>
          </p>
        </div>

        {this.state.images.map((i) => {
          const onRemove = () =>
            this.setState({
              images: this.state.images.filter((im) => im !== i),
            });

          return (
            <div key={i.name} className={`image ${i.url ? "" : "loading"}`}>
              {i.url ? (
                <button onClick={onRemove}>
                  <img src="/images/cross.png" alt="Delete" />
                </button>
              ) : null}
              {i.url ? (
                <img key={i.name} src={i.url} alt={i.name} />
              ) : (
                <img
                  src="/images/spinner.svg"
                  alt="Loading"
                  className="spinner"
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
