import React from "react";
import qs from "query-string";
import * as API from "@ben-toogood/hutch";
import { Link } from "react-router-dom";
import ClientBranding from "../../components/ClientBranding";
import "./Tracking.scss";

import DidNotArrive from "./DidNotArrive";
import CancelOrder from "./CancelOrder";
import EditAddress from "./EditAddress";
import HoldOrder from "./HoldOrder";
import MissingItems from "./MissingItems";
import ReturnOrder from "./ReturnOrder";

interface Props {
  match?: any;
  location: any;
}

interface State {
  client?: API.Client;
  order?: Order;
  loading?: boolean;
  view: view;
  error?: string;
  success?: string;
}

type view =
  | "main"
  | "edit-address"
  | "hold-order"
  | "cancel-order"
  | "missing-items"
  | "did-not-arrive"
  | "damaged"
  | "return";

export interface Item {
  id: string;
  quantity?: number;
  name?: string;
  description?: string;
  return_unsupported?: boolean;
  reason?: API.ReturnItemReason;
}

interface DeliveryException {
  reason?: API.ExceptionReason;
  status?: API.DeliveryStatus;
}

export interface Delivery {
  carrier?: API.Carrier;
  status?: API.DeliveryStatus;
  tracking_number?: string;
  tracking_link?: string;
  label_url?: string;
  exception?: DeliveryException;
  can_report_did_not_arrive?: boolean;
  can_report_did_not_arrive_on?: string;
  collection_url?: string;
}

interface Issue {
  status: API.IssueStatus;
  reason: API.IssueReason;
  reported_by: API.IssueReporter;
  customer_description?: string;
  image_urls?: string[];
  replacement_order_id?: string;
}

export interface Return {
  status: API.ReturnStatus;
  delivery?: Delivery;
  address?: API.Address;
}

interface Order {
  reference?: string;
  channel?: string;
  channel_id?: string;
  items?: Item[];
  status?: API.OrderStatus;
  blocked_reason?: API.BlockedReason;
  shipping_method?: API.ShippingMethod;
  shipping_address?: API.Address;
  delivery?: Delivery;
  issue?: Issue;
  return?: Return;
  client?: API.Client;
  functionality?: Functionality;
}

interface Functionality {
  cancel_order?: boolean;
  hold_order?: boolean;
  edit_address?: boolean;
  return_order?: boolean;
  freepost_return?: boolean;
  return_address?: string;
}

export default class Tracking extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { order: props.location?.state?.order, view: "main" };
  }

  componentDidMount() {
    if (!this.state.order) this.loadOrder();

    API.Call("support/LogEvent", {
      id: this.props.match.params.id,
      description: "Tracking Opened",
    }).catch(console.warn);
  }

  loadOrder(callback?: () => void) {
    const { id } = this.props.match.params;

    API.Call("support/ReadOrder", { id })
      .then((order: Order) => this.setState({ order }))
      .catch((err) => alert(err))
      .finally(callback);
  }

  componentDidUpdate(prevProps: Props) {
    // order has changed, e.g when viewing a replacement link
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ order: undefined });
      this.loadOrder();
    }
  }

  setView(
    view: view,
    options?: {
      error?: string;
      success?: string;
      callback?: Function;
    }
  ) {
    console.log("Set view", view, options);
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        loading: false,
        view: view,
        error: options?.error,
        success: options?.success,
      });
      if (options?.callback) options.callback();
    }, 250);
  }

  render() {
    const loadingData = !this.state.order;

    let view: JSX.Element | undefined;
    if (!loadingData) {
      switch (this.state.view) {
        case "main":
          view = this.renderMain();
          break;
        case "edit-address":
          view = this.renderEditAddress();
          break;
        case "hold-order":
          view = this.renderHoldOrder();
          break;
        case "cancel-order":
          view = this.renderCancelOrder();
          break;
        case "did-not-arrive":
          view = this.renderDidNotArrive();
          break;
        case "missing-items":
        case "damaged":
          view = this.renderMissingItems();
          break;
        case "return":
          view = this.renderReturn();
          break;
      }
    }

    return (
      <ClientBranding
        pageTitle="Tracking"
        client={this.state.order?.client}
        loading={loadingData || this.state.loading}
        overrides={qs.parse(this.props.location.search)}
      >
        {view}
      </ClientBranding>
    );
  }

  unholdOrder() {
    const { id } = this.props.match.params;
    this.setState({ loading: true });

    API.Call("support/UnholdOrder", { id })
      .then(() => {
        this.setView("main", {
          callback: () => {
            this.setState({
              order: {
                ...this.state.order!,
                status: API.OrderStatus.Pending,
                blocked_reason: API.BlockedReason.Unknown,
              },
            });
          },
        });
      })
      .catch(() => {
        this.setView("main", {
          error: `Sorry, we were unable to unhold your order. Please contact support for more information`,
        });
      });
  }

  renderMain() {
    const order = this.state.order!;
    const { error, success } = this.state;

    let message, action: JSX.Element | undefined;
    let secondaryActions: JSX.Element[] = [];

    let contactButton: JSX.Element | undefined;
    if (order.client?.support_email) {
      contactButton = (
        <button
          onClick={() =>
            (window.location = `mailto:${order.client!.support_email}` as any)
          }
          className="action"
        >
          Email us
        </button>
      );
    }

    /*eslint no-labels: ["error", { "allowSwitch": true }] */
    statusSwitch: switch (order.status) {
      case API.OrderStatus.Blocked:
        switch (order.blocked_reason) {
          case API.BlockedReason.InvalidAddress:
            message = (
              <p>
                Oops! It looks like your address wasn't recognised by our
                systems. Please double check your address so we can dispatch
                your order!
              </p>
            );
            action = (
              <button
                onClick={() => this.setView("edit-address")}
                className="action"
              >
                Edit address
              </button>
            );
            break statusSwitch;
          case API.BlockedReason.FraudRisk:
            message = (
              <p>
                Our payment processor has flagged your order for additional
                checks. We'll be in touch shortly to request some additional
                information
              </p>
            );
            action = contactButton;
            break statusSwitch;
          case API.BlockedReason.CustomerRequest:
            message = (
              <p>
                We're holding onto your order as requested! Let us know once
                you're ready for us to dispatch it
              </p>
            );
            action = (
              <button onClick={this.unholdOrder.bind(this)} className="action">
                Dispatch my order
              </button>
            );
            break statusSwitch;
        }
      // falls through
      case API.OrderStatus.Pending:
      case API.OrderStatus.Picked:
      case API.OrderStatus.Packed:
      case API.OrderStatus.QualityAssured:
        message = (
          <p>
            Thank you for your order{" "}
            <span role="img" aria-label="thank-you">
              🙏
            </span>{" "}
            We are working to dispatch your order as quickly as possible. Once
            your order is on its way, tracking information will be available
            here.
          </p>
        );
        break;
      case API.OrderStatus.Cancelled:
        message = (
          <p>
            Your order has been cancelled. If you believe this was a mistake,
            please let us know!
          </p>
        );
        action = success ? undefined : contactButton;
        break;
      case API.OrderStatus.Shipped:
        if (!order.delivery) {
          message = (
            <p>Your order has been shipped and will be with you soon!</p>
          );
          break;
        }

        if (
          order.delivery.status !== API.DeliveryStatus.Unavailable &&
          order.delivery.status !== API.DeliveryStatus.Completed
        ) {
          action = (
            <a
              target="blank"
              href={order.delivery.tracking_link}
              className="action"
            >
              Track my delivery
            </a>
          );
        }

        if (order.delivery.exception) {
          switch (order.delivery.exception.reason) {
            case API.ExceptionReason.Delayed:
              message = (
                <p>
                  We are tracking your order with{" "}
                  {API.HumanizeCarrier[order.delivery.carrier!]} and are
                  investigating why it's delayed. Sorry for any inconvenience
                  this has caused.
                </p>
              );
              break statusSwitch;
            case API.ExceptionReason.UnexpectedStatus:
              switch (order.delivery.exception.status) {
                case API.DeliveryStatus.Damaged:
                  message = (
                    <p>
                      Uh oh, it looks like your delivery was damaged in transit.
                      We are very sorry for this delay and we'll be in touch
                      with you shortly to organise a replacement.
                    </p>
                  );
                  action = contactButton;
                  break statusSwitch;
                default:
                  message = (
                    <p>
                      Uh oh, it looks like your delivery was unsuccessful. We
                      are investigating and will be in touch with you shortly.
                    </p>
                  );
                  action = contactButton;
                  break statusSwitch;
              }
          }
        }

        switch (order.delivery.status || API.DeliveryStatus.Pending) {
          case API.DeliveryStatus.Pending:
            message = (
              <p>
                {API.HumanizeCarrier[order.delivery.carrier!]} are on their way
                to collect your order!
              </p>
            );
            break;
          case API.DeliveryStatus.InTransit:
            message = (
              <p>
                Your order is with{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]} and it's on it's
                way to you!
              </p>
            );
            break;
          case API.DeliveryStatus.Unavailable:
            message = (
              <p>
                Your order has been dispatched with{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]}.
              </p>
            );
            break;
          case API.DeliveryStatus.ArrivedAtCustoms:
          case API.DeliveryStatus.HeldAtCustoms:
          case API.DeliveryStatus.ClearedCustoms:
            message = (
              <p>
                Your order is with customs and should be on its way to you soon!
              </p>
            );
            break;
          case API.DeliveryStatus.Attempted:
            message = (
              <p>
                An attempt was made to deliver your order. Please check the{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]} tracking link for
                more information.
              </p>
            );
            break;
          case API.DeliveryStatus.Scheduled:
            message = (
              <p>
                {API.HumanizeCarrier[order.delivery.carrier!]} has advised your
                delivery has been scheduled. Please contact{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]} via the tracking
                link for more information.
              </p>
            );
            break;
          case API.DeliveryStatus.Completed:
            message = (
              <p>
                Your order has been delivered by{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]}. Thank you for
                ordering with us, we hope you love your products!
              </p>
            );
            break;
          case API.DeliveryStatus.Failed:
          case API.DeliveryStatus.Damaged:
          case API.DeliveryStatus.Returned:
            message = (
              <p>
                Uh oh, it looks like your delivery was unsuccessful. Please
                contact us for more information.
              </p>
            );
            action = contactButton;
            break;
          case API.DeliveryStatus.AwaitingCollection:
            message = (
              <p>
                {API.HumanizeCarrier[order.delivery.carrier!]} has advised your
                order is awaiting collection. Please contact{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]} via the tracking
                link for more information.
              </p>
            );
            break;
          case API.DeliveryStatus.AwaitingInformation:
            message = (
              <p>
                {API.HumanizeCarrier[order.delivery.carrier!]} has advised they
                need additional information order to complete your delivery.
                Please contact {API.HumanizeCarrier[order.delivery.carrier!]}{" "}
                via the tracking link for more information.
              </p>
            );
            break;
          case API.DeliveryStatus.AwaitingPayment:
            message = (
              <p>
                {API.HumanizeCarrier[order.delivery.carrier!]} has advised that
                a payment of duties and taxes is required in order to complete
                your delivery. Please contact{" "}
                {API.HumanizeCarrier[order.delivery.carrier!]} via the tracking
                link for more information.
              </p>
            );
            break;
        }
    }

    if (order.return) {
      switch (order.return.status) {
        case API.ReturnStatus.Expected:
          switch (order.return.delivery?.status) {
            case API.DeliveryStatus.Pending:
            case API.DeliveryStatus.InTransit:
            case API.DeliveryStatus.Attempted:
            case API.DeliveryStatus.Scheduled:
              message = (
                <p>
                  Your return is with{" "}
                  {API.HumanizeCarrier[order.return.delivery.carrier!]} and on
                  its way to us!
                </p>
              );
              break;
            case API.DeliveryStatus.Completed:
              message = (
                <p>
                  We have received your return and it will be processed soon!
                </p>
              );
              break;
            case API.DeliveryStatus.Failed:
            case API.DeliveryStatus.Damaged:
            case API.DeliveryStatus.Returned:
              message = (
                <p>
                  It looks like there might have been an issue with your return
                  - please get in touch!
                </p>
              );
              action = contactButton;
              break;
            default:
              message = <p>We are waiting for your return!</p>;
              if (order.return?.delivery) {
                action = (
                  <a
                    className="action"
                    href={order.return.delivery?.tracking_link}
                    target="blank"
                  >
                    Track return
                  </a>
                );
                secondaryActions.push(
                  <a
                    className="action secondary"
                    href={order.return.delivery?.label_url}
                    target="blank"
                  >
                    Download Label
                  </a>
                );
                if (order.return.delivery?.collection_url) {
                  secondaryActions.push(
                    <a
                      className="action secondary"
                      href={order.return.delivery?.collection_url}
                      target="blank"
                    >
                      Book Collection
                    </a>
                  );
                }
              }
              break;
          }
          break;
        case API.ReturnStatus.Received:
          message = (
            <p>We have received your return and it will be processed soon!</p>
          );
          break;
        case API.ReturnStatus.Processed:
          message = (
            <p>
              Your return has been processed - please contact us for more
              information
            </p>
          );
          action = contactButton;
          break;
        case API.ReturnStatus.Cancelled:
          break;
      }
    } else if (order.issue) {
      if (order.issue.replacement_order_id) {
        message = (
          <p>
            We're sorry there was an issue with your order. We've sent a
            replacement which can be tracked using the link below:
          </p>
        );
        action = (
          <Link
            className="action"
            to={`/t/${order.issue.replacement_order_id}`}
          >
            Track replacement
          </Link>
        );
      } else if (order.issue.status === API.IssueStatus.Reported) {
        message = (
          <p>
            We're sorry there was an issue with your order. We are investigating
            the issue and will be in touch soon.
          </p>
        );
      }
    }

    let orderDetails: JSX.Element[] = [];
    let orderActions: JSX.Element[] = [];

    if (order.channel_id)
      orderDetails.push(
        <div key="details-order-number">
          <p>Order Number</p>
          <p>{order.channel_id}</p>
        </div>
      );

    if (order.shipping_address?.name)
      orderDetails.push(
        <div key="details-delivering-to">
          <p>Delivering To</p>
          <p>{order.shipping_address.name}</p>
        </div>
      );

    if (order.shipping_address) {
      const addr = [
        order.shipping_address.line_one,
        order.shipping_address.postcode,
      ]
        .map((c) => c?.trim())
        .filter((c) => c?.length)
        .join(", ");

      orderDetails.push(
        <div key="details-shipping-address">
          <p>Shipping Address</p>
          <p style={{ textTransform: "capitalize" }}>{addr}</p>
        </div>
      );
    }

    if (order.shipping_method)
      orderDetails.push(
        <div key="details-shipping-method">
          <p>Shipping Method</p>
          <p>{API.HumanizeShippingMethod[order.shipping_method]}</p>
        </div>
      );

    if (
      order.delivery?.status &&
      order.delivery?.status !== API.DeliveryStatus.Unavailable
    )
      orderDetails.push(
        <div key="details-delivery-status">
          <p>Delivery Status</p>
          <p>
            {API.HumanizeDeliveryStatus[order.delivery.status]}{" "}
            {order.delivery.exception ? "(Being investigated)" : ""}
          </p>
        </div>
      );

    if (
      order.delivery &&
      order.delivery?.status !== API.DeliveryStatus.Unavailable
    )
      orderDetails.push(
        <div key="details-tracking-number">
          <p>Tracking Number</p>
          <a href={order.delivery.tracking_link} target="blank">
            {API.HumanizeCarrier[order.delivery.carrier!]} (
            {order.delivery.tracking_number})
          </a>
        </div>
      );

    if (
      (order.status === API.OrderStatus.Pending ||
        order.status === API.OrderStatus.Blocked) &&
      order.functionality?.edit_address
    ) {
      orderActions.push(
        <div
          key="actions-edit-address"
          onClick={() => this.setView("edit-address")}
        >
          <p>Modify delivery address</p>
        </div>
      );
    }

    // if(order.status === API.OrderStatus.Pending || order.status === API.OrderStatus.Blocked) orderActions.push(<div key='actions-upgrade-shipping'>
    //   <p>Upgrade shipping method</p>
    // </div>);

    if (
      order.status === API.OrderStatus.Pending &&
      order.functionality?.hold_order
    )
      orderActions.push(
        <div
          key="actions-hold-order"
          onClick={() => this.setView("hold-order")}
        >
          <p>Do not ship my order yet</p>
        </div>
      );

    if (
      order.status !== API.OrderStatus.Cancelled &&
      order.status !== API.OrderStatus.Shipped &&
      order.functionality?.cancel_order
    ) {
      orderActions.push(
        <div
          key="actions-cancel-order"
          onClick={() => this.setView("cancel-order")}
        >
          <p>I need to cancel my order</p>
        </div>
      );
    }

    if (
      (order.delivery?.status === API.DeliveryStatus.Completed ||
        order.delivery?.status === API.DeliveryStatus.Unavailable) &&
      !order.issue &&
      !order.return &&
      order.items?.length
    ) {
      orderActions.push(
        <div
          key="actions-order-did-not-arrive"
          onClick={() => this.setView("did-not-arrive")}
        >
          <p>I have not received my order</p>
        </div>
      );

      orderActions.push(
        <div
          key="actions-one-or-more-items-missing"
          onClick={() => this.setView("missing-items")}
        >
          <p>Some of my items did not arrive</p>
        </div>
      );

      orderActions.push(
        <div
          key="actions-one-or-more-items-damaged"
          onClick={() => this.setView("damaged")}
        >
          <p>Some of my items arrived damaged</p>
        </div>
      );

      if (order.functionality?.return_order)
        orderActions.push(
          <div
            key="actions-return-order"
            onClick={() => this.setView("return")}
          >
            <p>I would like to return my order</p>
          </div>
        );
    }

    if (order.return?.status === API.ReturnStatus.Expected) {
      orderActions.push(
        <div
          key="actions-return-instructions"
          onClick={() => this.setView("return")}
        >
          <p>View return instructions</p>
        </div>
      );
    }

    return (
      <div className="Tracking">
        {order.client?.branding_logo_url ? (
          <img
            id="logo"
            src={order.client?.branding_logo_url}
            alt={order.client?.name}
          />
        ) : (
          <h1 id="logo-text">{order.client?.name}</h1>
        )}

        {error ? (
          <p className="error">{error}</p>
        ) : success ? (
          <p className="success">{success}</p>
        ) : (
          message
        )}

        {action || secondaryActions.length ? (
          <div className="action-container">
            {action}
            {secondaryActions}
          </div>
        ) : null}

        {orderDetails.length ? <h2>Order Details</h2> : null}
        {orderDetails.length ? (
          <section className="rows rows-secondary">{orderDetails}</section>
        ) : null}

        {orderActions.length ? <h2>Get help with your order</h2> : null}
        {orderActions.length ? (
          <section className="rows rows-actions">{orderActions}</section>
        ) : null}
      </div>
    );
  }

  renderEditAddress() {
    const onSuccess = () => {
      this.setView("main", { success: "Your address has been updated!" });
      this.loadOrder();
    };

    return (
      <EditAddress
        onSuccess={onSuccess}
        goBack={() => this.setView("main")}
        id={this.props.match.params.id}
        address={this.state.order!.shipping_address!}
      />
    );
  }

  renderHoldOrder(): JSX.Element {
    const onSuccess = () => {
      this.setView("main");
      this.loadOrder();
    };

    const onError = () => {
      this.setView("main", {
        error:
          "Sorry, we were unable to hold your order. Please contact support for more information",
      });
    };

    return (
      <HoldOrder
        id={this.props.match.params.id}
        goBack={() => this.setView("main")}
        onError={onError}
        onSuccess={onSuccess}
      />
    );
  }

  renderCancelOrder(): JSX.Element {
    const { id } = this.props.match.params;

    const onComplete = () => {
      this.setView("main", {
        success:
          "Your order has been cancelled. We hope to see you again soon!",
        callback: () =>
          this.setState({
            order: { ...this.state.order, status: API.OrderStatus.Cancelled },
          }),
      });
    };

    const onError = () => {
      this.setView("main", {
        error: `Sorry, there was an error cancelling your order. Please contact customer support for more information`,
      });
    };

    return (
      <CancelOrder
        id={id}
        status={this.state.order!.status!}
        onError={onError}
        onComplete={onComplete}
        goBack={() => this.setView("main")}
        onHold={() => this.setView("hold-order")}
      />
    );
  }

  renderMissingItems(): JSX.Element {
    const { id } = this.props.match.params;

    const onComplete = () => {
      this.loadOrder(() =>
        this.setView("main", {
          success: `We are very sorry there was an issue with your delivery, we are launching an investigatation and will get back to you soon`,
        })
      );
    };

    const onError = () => {
      this.setView("main", {
        error: `Sorry, there was an error reporting this issue. Please contact customer support for more information`,
      });
    };

    return (
      <MissingItems
        id={id}
        onError={onError}
        onComplete={onComplete}
        source={this.state.view as any}
        items={this.state.order?.items!}
        goBack={() => this.setView("main")}
        setLoading={(loading: boolean) => this.setState({ loading })}
      />
    );
  }

  renderDidNotArrive(): JSX.Element {
    const { id } = this.props.match.params;

    const onComplete = () => {
      this.setView("main", {
        success: `We are very sorry your parcel has not arrived, we will launch an investigatation with ${
          API.HumanizeCarrier[this.state.order!.delivery?.carrier!]
        } and get back to you soon`,
        callback: this.loadOrder,
      });
    };

    const onError = () => {
      this.setView("main", {
        error: `Sorry, there was an error reporting this issue. Please contact customer support for more information`,
      });
    };

    return (
      <DidNotArrive
        id={id}
        onError={onError}
        onComplete={onComplete}
        goBack={() => this.setView("main")}
        setLoading={(loading: boolean) => this.setState({ loading })}
        canReportLost={!!this.state.order!.delivery!.can_report_did_not_arrive}
        canReportLostOn={
          this.state.order!.delivery!.can_report_did_not_arrive_on
        }
      />
    );
  }

  renderReturn(): JSX.Element {
    const { id } = this.props.match.params;

    const onComplete = () => {
      this.setView("main", {
        callback: this.loadOrder,
      });
    };

    const onError = () => {
      this.setView("main", {
        error: `Sorry, there was an error submitting this return. Please contact customer support for more information`,
      });
    };

    const setDelivery = (d?: Delivery) => {
      this.setState({
        order: {
          ...this.state.order,
          return: {
            status: API.ReturnStatus.Expected,
            delivery: d,
          },
        },
      });
    };

    return (
      <ReturnOrder
        id={id}
        onError={onError}
        onComplete={onComplete}
        items={this.state.order!.items || []}
        return={this.state.order?.return}
        reference={this.state.order!.reference!}
        goBack={() => this.setView("main")}
        setLoading={(loading: boolean) => this.setState({ loading })}
        setDelivery={setDelivery}
      />
    );
  }
}
