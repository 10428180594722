import React from 'react';
import qs from 'query-string';
import * as API from '@ben-toogood/hutch';
import ClientBranding from '../../components/ClientBranding';
import './Tracking.scss';

interface Props {
  match?: any;
  location: any;
  history: any;
}

interface State {
  client?: API.Client;
  loading?: boolean;
  error?: string;
  postcode?: string;
  orderNumber?: string;
}

export default class Tracking extends React.Component<Props,State> {
  readonly state: State = {};

  constructor(props: Props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    const params = new URLSearchParams(props.location.search);
    this.state = {
      orderNumber: params.get('order') || '',
      postcode: params.get('postcode') || '',
    };
  }

  componentDidMount() {
    if(this.state.orderNumber && this.state.postcode) this.onSubmit();
  }

  onSubmit(e?: React.FormEvent) {
    e?.preventDefault();

    if(this.state.loading) return;
    this.setState({ loading: true });
    
    API.Call("support/Search", { postcode: this.state.postcode, order_number: this.state.orderNumber, client_slug: this.props.match.params.slug })
      .then(order => this.props.history.push(`/t/${order.id}`, { order }))
      .catch(err => this.setState({ error: err, loading: false }));
  }

  render() {
    const { client, loading } = this.state;

    return <ClientBranding
            pageTitle='Tracking'
            client={client}
            loading={!client || loading}
            clientSlug={this.props.match.params.slug}
            overrides={qs.parse(this.props.location.search)} 
            onClientSet={client => this.setState({ client })}>
      { client ? this.renderInner() : null }
    </ClientBranding>
  }

  renderInner() {
    const { client, error } = this.state;

    return <div className='Tracking Search'>
      { 
        client?.branding_logo_url ?
        <img id='logo' src={client?.branding_logo_url} alt={client?.name} /> :
        <h1 id='logo-text'>{client?.name}</h1>
      }

      { error ? <p className='error'>{error}</p> : null }

      <p>Thanks for ordering with {client?.name}. Please enter your order number and postcode below to find out more about your order.</p>

      <form onSubmit={this.onSubmit}>
        <label>Order Number</label>
        <input 
          required
          autoFocus
          type='text' 
          placeholder='1234' 
          value={this.state.orderNumber} 
          onChange={e => this.setState({ orderNumber: e.target.value })}/>

        <label>Postcode</label>
        <input
          required
          type='text'
          placeholder='WC1X 8ED'
          value={this.state.postcode}
          onChange={e => this.setState({ postcode: e.target.value })} />

        <input type='submit' value='Submit' />
      </form>
    </div>
  }
}