import React from 'react';
import * as API from '@ben-toogood/hutch';
import ImageUpload from './ImageUpload';
import { Item } from './index';

interface Props {
  id: string;
  source: 'missing-items' | 'damaged';
  items: Item[];
  goBack: () => void;
  onComplete: () => void;
  onError: () => void;
  setLoading: (l: boolean) => void;
}

type cause = 'hutch' | 'carrier';

interface State {
  loading?: boolean;
  cause?: cause;
  view: 'cause' | 'items' | 'detail';
  items: Item[];
  imageURLs: string[];
  note: string;
}

export default class MissingItems extends React.Component<Props,State> {
  constructor(props: Props) {
    super(props);

    // do not ask the customer which items are damaged if there is only one
    const totalUnits = props.items.reduce((t,i) => t + (i.quantity || 0), 0);

    this.state = {
      view: 'cause',
      items: totalUnits > 1 ? props.items.map(i => ({ ...i, quantity: 0 })).sort((a,b) => (a.name || '') > (b.name || '') ? 1 : -1) : props.items,
      imageURLs: [],
      note: '',
    }

    this.reportIssue = this.reportIssue.bind(this);
    this.confirmItems = this.confirmItems.bind(this);
  }

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Missing Items",
    }).catch(console.warn);
  }

  confirmItems() {
    if(this.state.cause === 'hutch') {
      this.reportIssue();
      return;
    }

    this.setState({ view: 'detail' });
  }

  setCause(cause: cause) {
    const totalUnits = this.props.items.reduce((t,i) => t + (i.quantity || 0), 0);

    // no need to ask for pictures because you can't take a photo of an item which didn't arrive
    if(cause === 'hutch' && totalUnits < 2) {
      this.reportIssue(cause);
      return;
    }
    
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Confirmed Cause: " + cause,
    }).catch(console.warn);

    this.setState({ view: totalUnits < 2 ? 'detail' : 'items', cause });
  }

  reportIssue(cause?: cause) {
    this.props.setLoading(true);

    const reason = (cause || this.state.cause) === 'carrier' ? API.IssueReason.CarrierDamaged : API.IssueReason.MissingItems;

    API.Call("support/CreateIssue", {
      id: this.props.id,
      image_urls: this.state.imageURLs,
      items: this.state.items,
      notes: this.state.note,
      reason,
    })
    .then(rsp => this.props.onComplete())
    .catch(err => this.props.onError());

    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Missing Items Reported",
      metadata: {
        reason,
      },
    }).catch(console.warn);
  }

  render() {
    const { source } = this.props;
    const { cause, view, items } = this.state;

    let elements: JSX.Element[] = [];
    switch(view) {
    case 'cause':
      elements = [
        <p key='subtitle' className='subtitle'>We're sorry that some of your items {source === 'missing-items' ? 'have not arrived' : 'arrived damaged'}. In order to investigate this incident, we just need to ask a few questions. When you received your delivery, how did it look?</p>,
        <section key='actions' className='rows rows-actions'>
          <div onClick={() =>this.setCause('carrier')}>
            <p>The parcel looked like it had been opened in transit or repacked</p>
          </div>
          
          <div onClick={() =>this.setCause('carrier')}>
            <p>The parcel arrived damaged</p>
          </div>
          
          <div onClick={() =>this.setCause('hutch')}>
            <p>The parcel was in good condition</p>
          </div>
        </section>
      ];
      break;
    case 'items':
      elements = [
        <p key='subtitle' className='subtitle'>Please confirm which items {source === 'missing-items' ? <span>have <strong>not</strong> arrived</span> : 'arrived damaged'}</p>,
        <section key='actions' className='rows rows-secondary items'>
          { items.map((i, idx) => {
            const max = this.props.items[idx].quantity;
            
            const changeQuantity = (q: number) => {
              let newItems = [...items];
              newItems[idx] = { ...i, quantity: q };
              this.setState({ items: newItems });
            }

            return <div key={i.id} className='item'>
              { i.description?.length ? <p>{i.name} - {i.description}</p> : <p>{i.name}</p>}

              <p className='number'>{i.quantity}</p>
              <button onClick={() => changeQuantity(i.quantity! - 1)} disabled={i.quantity === 0}>-</button>
              <button onClick={() => changeQuantity(i.quantity! + 1)} disabled={i.quantity === max}>+</button>
            </div>
          })}
        </section>,
        <button key='items' onClick={this.confirmItems} disabled={!this.state.items.reduce((t,i) => t + i.quantity!,0)} className='submit'>{this.state.cause === 'hutch' ? 'Submit' : 'Continue'}</button>
      ];
      break;
    case 'detail':
      if(this.props.source === 'missing-items') {
        // missing items as a result of courier error
        elements = [
          <p key='subtitle' className='subtitle'>Please provide pictures of the packaging your order arrived in and any additional details which may help when investigating this issue</p>,
          <ImageUpload key='image-upload' setImageURLs={imageURLs => this.setState({ imageURLs })} />,
          <textarea
            key='note'
            value={this.state.note}
            onChange={e => this.setState({ note: e.target.value || '' })} />,
          <button key='pictures' onClick={() => this.reportIssue()} disabled={!this.state.imageURLs.length} className='submit'>Submit</button>
        ];
      } else {
        // damaged items as a result of courier error
        const unitsDamaged = this.state.items.reduce((t, i) => t + i.quantity!, 0);
        elements = [
          <p key='subtitle' className='subtitle'>Please provide a description of what's wrong with the item{unitsDamaged === 1 ? '' : 's'} as well as pictures of the item{unitsDamaged === 1 ? '' : 's'} {cause === 'carrier' ? 'and the outside of the package they arrived in.' : ''}</p>,
          <ImageUpload key='image-upload' setImageURLs={imageURLs => this.setState({ imageURLs })} />,
          <textarea
            autoFocus
            key='note'
            value={this.state.note}
            onChange={e => this.setState({ note: e.target.value || '' })} />,
          <button key='pictures' onClick={() => this.reportIssue()} disabled={!this.state.imageURLs.length || !this.state.note.length} className='submit'>Submit</button>
        ];
      }
      break;
    }

    return <div className='Tracking MissingItems'>
      <button className='go-back' onClick={this.props.goBack}>
        <span role='img' aria-label='Go back'>↩</span> Go back
      </button>

      <h2>Some of my items {source === 'missing-items' ? 'have not arrived' : 'arrived damaged'}</h2>

      { elements }
   </div>
  }
}