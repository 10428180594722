import React from 'react';
import * as API from '@ben-toogood/hutch';
import { Item, Delivery, Return } from './index';

interface Props {
  id: string;
  items: Item[];
  return?: Return;
  reference: string;
  goBack: () => void;
  onComplete: () => void;
  onError: () => void;
  setLoading: (l: boolean) => void;
  setDelivery: (d?: Delivery) => void;
}

interface State {
  view: 'items' | 'refund-method' | 'reasons' | 'label';
  items: Item[];
  refundMethod?: API.RefundMethod;

  delivery?: Delivery;
  address?: API.Address;
  refundMethods?: API.RefundMethod[];
  reasons?: API.ReturnItemReason[];
}

export default class ReturnOrder extends React.Component<Props,State> {
  constructor(props: Props) {
    super(props);

    this.state = {

      address: props.return?.address,
      delivery: props.return?.delivery,
      view: props.return?.address ? 'label' : 'items',
      items: props.items.map(i => ({ ...i, quantity: 0 })).sort((a,b) => (a.name || '') > (b.name || '') ? 1 : -1),
    }

    this.createReturn = this.createReturn.bind(this);
    this.onItemsConfirmed = this.onItemsConfirmed.bind(this);
    this.onReasonsConfirmed = this.onReasonsConfirmed.bind(this);
  }

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Return Order",
    }).catch(console.warn);

    this.loadReturnOptions();
  }

  loadReturnOptions() {
    API.Call("support/ReturnOptions", { order_id: this.props.id })
      .then((rsp: { refund_methods?: API.RefundMethod[], return_reasons?: API.ReturnItemReason[] }) => {
        const refundMethods = (rsp.refund_methods || []).sort((a,b) => API.HumanizeRefundMethod[a] > API.HumanizeRefundMethod[b] ? 1 : -1);
        const reasons = (rsp.return_reasons || []).sort((a,b) => a.description > b.description ? 1 : -1);
        this.setState({ reasons, refundMethods, refundMethod: refundMethods[0] });
      })
      .catch(err => {
        this.props.onError();
      });
  }

  onItemsConfirmed() {
    this.props.setLoading(true);

    if((this.state.reasons || []).length > 1) {
      setTimeout(() => {
        this.setState({ view: 'reasons' });
        this.props.setLoading(false);
      }, 250);

      return;
    }

    this.onReasonsConfirmed();
  }

  onReasonsConfirmed() {
    this.props.setLoading(true);

    if((this.state.refundMethods || []).length > 1) {
      setTimeout(() => {
        this.setState({ view: 'refund-method' });
        this.props.setLoading(false);
      }, 250);
      return;
    }

    this.setState({ refundMethod: (this.state.refundMethods || [])[0] });
    this.createReturn();
  }

  createReturn() {
    this.props.setLoading(true);
    debugger

    API.Call("support/CreateReturn", {
      id: this.props.id,
      refund_method: this.state.refundMethod,
      items: this.state.items.map(i => ({
        product_id: i.id,
        units: i.quantity,
        return_reason_id: i.reason?.id,
      })),
    })
    .then((rsp: { address: API.Address, delivery?: Delivery }) => {
      this.props.setLoading(false);
      this.props.setDelivery(rsp.delivery);
      this.setState({ view: 'label', address: rsp.address, delivery: rsp.delivery })
    })
    .catch(err => this.props.onError());

    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Return Requested",
    }).catch(console.warn);
  }

  render() {
    const { view, items } = this.state;

    let elements: (JSX.Element | null)[] = [];
    switch(view) {
    case 'items':
      elements = [
        <p key='subtitle' className='subtitle'>We're sorry to hear you're returning your order! Which items are you returning?</p>,
        <section key='actions' className='rows rows-secondary items'>
          { items.map((i, idx) => {
            const max = this.props.items[idx].quantity;
            
            const changeQuantity = (q: number) => {
              let newItems = [...items];
              newItems[idx] = { ...i, quantity: q };
              this.setState({ items: newItems });
            }

            return <div key={i.id} className='item'>
              <p>{[i.name, i.description].filter(x => x?.length).join(' - ')}</p>

              { i.return_unsupported ? <p className='return-unsupported'>This item is not eligible for return</p> : null }

              { i.return_unsupported ? null : <p className='number'>{i.quantity}</p> }
              { i.return_unsupported ? null : <button onClick={() => changeQuantity(i.quantity! - 1)} disabled={i.quantity === 0}>-</button> }
              { i.return_unsupported ? null : <button onClick={() => changeQuantity(i.quantity! + 1)} disabled={i.quantity === max}>+</button> }
            </div>
          })}
        </section>,
        <button key='items' onClick={this.onItemsConfirmed} disabled={!this.state.items.reduce((t,i) => t + i.quantity!,0)} className='submit'>Continue</button>
      ];
      break;
    case 'refund-method':
      elements = [
        <p key='subtitle' className='subtitle'>Which refund method would you like?</p>,
        <section key='actions' className='rows rows-actions'>
          { this.state.refundMethods?.map(m => {
            const selected = this.state.refundMethod === m;
            const onClick = () => this.setState({ refundMethod: selected ? undefined : m });
            return <div className={selected ? 'selected' : ''} onClick={onClick} key={m}>
              <p>{API.HumanizeRefundMethod[m]}</p>
              { selected ? <p className='tick'>✔</p> : null }
            </div>
          })}
        </section>,
        <button key='items' onClick={this.createReturn} disabled={!this.state.refundMethod} className='submit'>Continue</button>
      ];
      break;
    case 'reasons':
      const valid = !this.state.items?.find(i => i.quantity && !i.reason);
      elements = [
        <div>
          { this.state.items.filter(i => i.quantity)?.map(i => {
            let desc = i.name;
            if(i.description?.length) desc = `${desc} - ${i.description}`;

            return <section className='rows rows-actions'>
              <p key='subtitle' className='subtitle'>Why are you returning {desc}?</p>
              { this.state.reasons?.map(r => {
                const selected = i.reason?.id === r?.id;

                const onClick = () => {
                  let items = [...this.state.items];
                  const idx = items.findIndex(x => i.id === x.id);
                  items[idx].reason = selected ? undefined : r;
                  this.setState({ items });
                };

                return <div className={selected ? 'selected' : ''} onClick={onClick} key={r.id}>
                  <p>{r.description}</p>
                  { selected ? <p className='tick'>✔</p> : null }
                </div>
              })}
            </section>
          })}
        </div>,
        <button key='items' onClick={this.onReasonsConfirmed} disabled={!valid} className='submit'>Continue</button>
      ];
      break;
    case 'label':
      if(this.state.delivery?.collection_url?.length) {
        elements = [
          <p key='subtitle' className='subtitle'>Please post your return to us using the return label provided below. You can book a collection for free using the link below.</p>,
          <div className='action-container'>
            <a className='submit' href={this.state.delivery?.label_url} target='blank'>Download Label</a>
            <a className='submit' href={this.state.delivery?.collection_url} target='blank'>Book Collection</a>
            <button onClick={this.props.goBack} className='submit secondary'>Done</button>
          </div>,
        ];
      } else if(this.state.delivery) {
        elements = [
          <p key='subtitle' className='subtitle'>Please post your return to us using the return label provided below</p>,
          <div className='action-container'>
            <a className='submit' href={this.state.delivery?.label_url} target='blank'>Download Label</a>
            <button onClick={this.props.goBack} className='submit secondary'>Done</button>
          </div>
        ];
      } else {
        elements = [
          <p key='subtitle' className='subtitle'>Please post your return to <span className='address'>{API.AddressToString(this.state.address!)}</span><br /><br />Please include your order reference: <strong>{this.props.reference}</strong></p>,
          <div className='action-container'>
            <button onClick={this.props.goBack} className='submit'>Done</button>
          </div>,
        ];
      }
    }

    return <div className='Tracking ReturnOrder'>
      <button className='go-back' onClick={this.props.goBack}>
        <span role='img' aria-label='Go back'>↩</span> Go back
      </button>

      <h2>Return Order</h2>

      { elements }
   </div>
  }
}