import React from 'react';
import * as API from '@ben-toogood/hutch';

interface Props {
  id: string;
  address: API.Address;

  goBack: () => void;
  onSuccess: () => void;
}

interface State {
  error?: string;
  loading?: boolean;
  address: API.Address;
}

export default class EditAddress extends React.Component<Props,State> {
  constructor(props: Props) {
    super(props);
    this.state = { address: props.address };
  }

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Edit Address",
    }).catch(console.warn);
  }

  onSubmitEditAddress(e: React.FormEvent) {
    e.preventDefault();
    this.setState({ loading: true });

    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Edit Address",
    }).catch(console.warn);

    API.Call("support/UpdateAddress", { id: this.props.id , address: this.state.address })
      .then(this.props.onSuccess)
      .catch(err => this.setState({ error: err, loading: false }));
  }
  
  render() {
    const addr = this.state.address;
  
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ address: { ...addr, [e.target.name]: e.target.value }});
    }
  
    return <div className='Tracking'>
      <button className='go-back' onClick={this.props.goBack}>
        <span role='img' aria-label='Go back'>↩</span> Go back
      </button>
  
      <h2>Modify delivery address</h2>
      
      {
        this.state.error ?
        <p className='subtitle error'>{this.state.error}</p> :
        <p className='subtitle'>Update your address to any destination within {addr?.country || 'the same country'} and we'll amend your order free of charge! Shipping to a different country can impact delivery fees so please contact us via our website if this is required.</p>
      }
  
      <form onSubmit={this.onSubmitEditAddress.bind(this)}>
        <section>
          <h3>Contact Details</h3>
          <input onChange={onChange} required type='text' name='name' value={addr?.name} placeholder='Name' minLength={5} maxLength={30} />
          <input onChange={onChange} type='text' name='company' value={addr?.company} placeholder='Company' minLength={0} maxLength={30} />
          <input onChange={onChange} required type='phone_number' name='phone_number' value={addr?.phone_number} placeholder='Phone Number' minLength={5} maxLength={15} />
          <input onChange={onChange} required type='email' name='email' value={addr?.email} placeholder='Email Address' minLength={5} maxLength={50} />
        </section>
  
        <section>
          <h3>Shipping Address</h3>
          <input onChange={onChange} required type='text' name='line_one' value={addr?.line_one} placeholder='Line 1' minLength={1} maxLength={35} />
          <input onChange={onChange} type='text' name='line_two' value={addr?.line_two} placeholder='Line 2' minLength={1} maxLength={35} />
          <input onChange={onChange} required type='text' name='city' value={addr?.city} placeholder='City' minLength={1} maxLength={35} />
          <input onChange={onChange} type='text' name='postcode' value={addr?.postcode} placeholder='Postcode' maxLength={10} />
          <input disabled required type='text' name='country' value={addr?.country} placeholder='Country' minLength={1} maxLength={35} />
        </section>
  
        <input disabled={this.state.loading} type='submit' value='Submit' />
      </form>
    </div>
  }
}