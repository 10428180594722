import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ClaimGiftScene from './scenes/ClaimGift';
import TrackingScene from './scenes/Tracking';
import TrackingSearchScene from './scenes/Tracking/Search';
import './App.scss';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/g/:gift_id" component={ClaimGiftScene} />
          <Route path="/t/:id" component={TrackingScene} />
          <Route path="/:slug" component={TrackingSearchScene} />
          <Route component={Redirect} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

class Redirect extends React.Component {
  componentDidMount() {
    window.location.href = 'https://www.tryhutch.co.uk'
  }

  render(): JSX.Element {
    return <div />
  }
}