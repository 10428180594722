import React from 'react';
import moment from 'moment';
import * as API from '@ben-toogood/hutch';

interface Props {
  id: string;
  goBack: () => void;
  onComplete: () => void;
  onError: () => void;
  setLoading: (l: boolean) => void;
  canReportLost?: boolean;
  canReportLostOn?: string;
}

interface State {
  loading?: boolean;
}

export default class DidNotArrive extends React.Component<Props,State> {
  readonly state: State = {};

  constructor(props: Props) {
    super(props);
    this.reportIssue = this.reportIssue.bind(this);
  }

  componentDidMount() {
    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Page Opened: Did Not Arrive",
    }).catch(console.warn);
  }

  reportIssue() {
    this.props.setLoading(true);

    API.Call("support/CreateIssue", {
      id: this.props.id,
      reason: API.IssueReason.CarrierLost,
    })
    .then(rsp => this.props.onComplete())
    .catch(err => this.props.onError());

    API.Call("support/LogEvent", {
      id: this.props.id,
      description: "Action Taken: Carrier Lost Reported",
      metadata: {
        reason: API.IssueReason.CarrierLost,
      },
    }).catch(console.warn);
  }

  render() {
    return this.props.canReportLost ? this.renderReportLost() : this.renderHoldTight(); 
  }

  renderReportLost() {
    return <div className='Tracking'>
      <button className='go-back' onClick={this.props.goBack}>
        <span role='img' aria-label='Go back'>↩</span> Go back
      </button>

      <h2>I have not received my order</h2>

      <p className='subtitle'>We're sorry that this order has not arrived with you successfully. We ask that you check with your neighbours and other members of hour household before requesting an investigation.</p>

      <section className='rows rows-actions'>
        <div onClick={this.props.goBack}>
          <p>I found my delivery</p>
        </div>

        <div onClick={this.reportIssue}>
          <p>Please launch an investigation</p>
        </div>
      </section>
    </div>
  }

  renderHoldTight() {
    return <div className='Tracking'>
      <button className='go-back' onClick={this.props.goBack}>
        <span role='img' aria-label='Go back'>↩</span> Go back
      </button>

      <h2>I have not received my order</h2>

      <p className='subtitle'>Your delivery is still within it's expected time window. Please check back in {moment(this.props.canReportLostOn).fromNow()} if the delivery has still not arrived. </p>
    </div>;
  }
}