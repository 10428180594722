import React from 'react';
import ReactDOM from 'react-dom';
import * as Cookies from 'js-cookie';
import * as API from '@ben-toogood/hutch';
import App from './App';
import './index.scss';

// set cookies to expire in 30 days, they might expire sooner than this (e.g. the access token
// but the API library will handle the refresh logic).
let expires = new Date()
expires.setDate(expires.getDate() + 30);

API.Init({
  DefaultCallOptions: { SkipAuth: true },
  GetScopes: () => new Promise(async (resolve: (result: API.AuthScopes) => void, reject: (err: any) => void) => {
    const val = Cookies.get(API.AuthScopesKey);
    resolve(val ? JSON.parse(val) : {});
  }),
  SetScopes: (scopes: API.AuthScopes) => new Promise(async (resolve: (any: any) => void, reject: (err: any) => void) => {
    Cookies.set(API.AuthScopesKey, scopes, { expires });
    resolve(null);
  }),
  GetAccessToken: () => Promise.resolve(Cookies.get(API.AccessTokenKey)),
  SetAccessToken: (token: string) => Promise.resolve(Cookies.set(API.AccessTokenKey, token, { expires })),
  GetRefreshToken: () => Promise.resolve(Cookies.get(API.RefreshTokenKey)),
  SetRefreshToken: (token: string) => Promise.resolve(Cookies.set(API.RefreshTokenKey, token, { expires })),
  RemoveAccessToken: () => Promise.resolve(Cookies.remove(API.AccessTokenKey)),
  RemoveRefreshToken: () => Promise.resolve(Cookies.remove(API.RefreshTokenKey)),
  RedirectToLogin: () => window.location.href = '/login',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
